
import React from "react";

import "css/main.css";
import "css/fonts.css";

import Seo from "components/SeoHead";
import WayOfTheWeaver from "components/Community/WayOfTheWeaver"

// title, content, footer
import data from "data/community/way-of-the-weaver-data"

function SocialFeedPage() {
    return (
        <>
            <Seo title="Way of The Weaver - Skyweaver" />
            <WayOfTheWeaver
                title={data.title}
                text={data.text}
                img={data.img}
                content={data.content}
            />
        </>
    )
}

export default SocialFeedPage