const data = {
    title: "Way Of The Weaver",
    text: `Skyweaver aims to promote friendly competition and a spirit of fairness in a worldwide community that plays as one. The Way of the Weaver is a sentiment that permeates our vision for the game, and we expect our users to embrace it in a positive coexistence. If you have any questions, please check our <a style="color: white; text-decoration: underline" href="/terms-of-service">terms</a> and reach out to our Community Team. <br/><br/> Reach for the Sky!`,
    img: {
        url: "/images/community/way-of-the-weaver/0.jpg",
        alt: "Way Of The Weaver"
    },
    content: [
        {
            title: "Transcend yourself while being mindful of the rules",
            img: {
                url: "/images/community/way-of-the-weaver/1.jpg",
                alt: "Transcend yourself while being mindful of the rules"
            },
            textHTML: "Skyweaver is a game of wits and skill, and while some players are gifted with superior deck-building abilities, most Weavers learn through experimentation and a communal effort of unravelling new decks and strategies. This is only made possible by a strict adherence to the rules, both those intrinsic to the gameplay, and those around the platform the game is played on. Help us maintain a secure and fun experience for players of all skill levels."
        },
        {
            title: "Competition should not lead to hate",
            titleHTML: "Competition should <br/> not lead to hate",
            img: {
                url: "/images/community/way-of-the-weaver/2.jpg",
                alt: "Competition should not lead to hate"
            },
            textHTML: "Competition can be fierce and ruthless, but it should never escalate beyond the isles in Sky. If you win, be graceful and supportive. If you lose, learn from the experience and rise to compete again. Never make things personal, and never take things personally. <br/> Remember you can play against Weavers from completely different cultural backgrounds, genders and unique experiences, so try to build bridges instead of burning them. If competing makes you angry or anxious, reach out to the community and Horizon for help. We are here for you."
        },
        {
            title: "Have fun, but not at the expense of others",
            img: {
                url: "/images/community/way-of-the-weaver/3.jpg",
                alt: "Have fun, but not at the expense of others"
            },
            textHTML: "Fun is subjective, but we should always strive to make things enjoyable for everyone. Losing is rarely ‘good’, but experiencing loss in an embracing community that’s ready to lift you up is a much more satisfying scenario. At the same time, oppressing, humiliating, and demeaning your community members serves only selfish and destructive needs. Today’s newbie is tomorrow’s veteran, and making sure people come back to Sky, even after losing, makes everyone’s time on Skyweaver much more enjoyable. If you find yourself beating others in the game and are more skilled than other players, rather than talk down at your peers, offer them tips, tricks and strategies. Become a figure players can look up to for guidance and advice. Be kind, don’t be cocky. Have fun!"
        },
        {
            title: "The greatest alchemy is transforming your energy into positivity and creativity",
            img: {
                url: "/images/community/way-of-the-weaver/4.jpg",
                alt: "The greatest alchemy is transforming your energy into positivity and creativity"
            },
            textHTML: "Interacting with anything makes us think. This impulse can be channeled in a lot of ways. Use this energy in a positive manner, by making a difference in the community, or by fueling it into other creative ventures, such as drawing, painting, writing, cosplaying, coding, designing or creating content. Be generous with your skills and potential. We, as creators ourselves, would love to see what you can do."
        },
        {
            title: "Everyone’s eyes keep everyone safe",
            titleHTML: "Everyone’s eyes <br/> keep everyone safe",
            img: {
                url: "/images/community/way-of-the-weaver/5.jpg",
                alt: "Everyone’s eyes keep everyone safe"
            },
            textHTML: "A game is only fair when everyone is playing by the rules. We will do our best to keep Sky a safe, inclusive and fun place to visit, but we cannot be everywhere at all times. We need our community’s vigilance to make sure everything is happening as it should. This also extends to the community at large and in creators’ channels. Creators give their time and energy to share their content with others. Please treat them with the utmost respect. We do not want Weavers or creators to be subject to any form of abuse, sexual harassment, stalking or hateful conduct. <br/> We do not tolerate discrimination, hate speech, violence, illegal activities or anything that jeopardizes the safety of our community and staff members. If you see any transgression, report it to us."
        }
    ]
}

export default data