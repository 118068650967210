import React from "react"

import Layout from "components/Blog/Layout"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { FlexParent } from "components/Layouts/Utils"

import Landing from "./Landing"
import Block from "./Block"

const WayOfTheWeaver = ({
    title, text, img, content
}) => {
    return (
        <Layout ignoreTopPadding>
            <Landing text={text} title={title} img={img} />

            <MainContentMaxWidth>
                <FlexParent
                    style={{
                        gap: "1.3rem"
                    }}
                    justifyContent="center">
                    {content.map((block, i) => <Block {...block} key={i} />)}
                </FlexParent>
            </MainContentMaxWidth>

        </Layout>
    )
}

export default WayOfTheWeaver