import React, { useState } from "react"
import styled from "styled-components"

import Dialog from "components/Layouts/Dialog"
import { H2, P } from "components/Typography"
import { Gradient, BackgroundImage } from "components/Layouts/Utils"

import Button from "components/Button"

const StyledBlock = styled.div`
    width: calc(50% - 1rem);


    @media (max-width: 768px) {
        width: 100%;
    }
`

const DialogImageBlock = styled.figure`
    height: 0;
    position: relative;
    padding-bottom: 40%;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid var(--lilacLowOpacity);

    img {
        width: 104%;
    }
`

const DialogTextBlock = styled.section`
    padding: 4rem;
    max-width: 46rem;
    margin: auto;

    @media (max-width: 750px) {
        padding: 2rem 2rem;
        padding-bottom: 8rem
    }
`

const BlockParent = styled.button`
    height: 0;
    position: relative;
    padding-bottom: 60%;
    width: 100%;
    overflow: hidden;

    &:hover img {
        transform: scale(1.1);w
    }
`

const TextSection = styled.div`
    max-width: 30rem;
    width: 100%;
    text-align: center;
    padding: 2rem;
    position: absolute;
    bottom: 0; left: 50%;
    transform: translateX(-50%);
    z-index: 2;
`

const Block = ({
    title, titleHTML, img, textHTML
}) => {
    const [isOpen, setOpen] = useState(false)

    const open = () => setOpen(true)
    const close = () => setOpen(false)

    return (
        <StyledBlock>
            <BlockParent onClick={open}>
                <BackgroundImage
                    loading="lazy"
                    src={img.url} />
                <Gradient />

                <TextSection>
                    <H2 color="white" size="2rem"><span dangerouslySetInnerHTML={{ __html: titleHTML ? titleHTML : title }} /></H2>
                    <Button as="div" bgColor="var(--background)" outlineColor="var(--lilac)" width="10rem">Read More</Button>
                </TextSection>
            </BlockParent>

            <Dialog small showDialog={isOpen} open={open} close={close}>
                <div>
                    <DialogImageBlock>
                        <BackgroundImage
                            loading="lazy"
                            src={img.url} />
                    </DialogImageBlock>
                    <DialogTextBlock>
                        <H2 color="white" size="2rem">{title}</H2>
                        <P><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                    </DialogTextBlock>
                </div>

            </Dialog>
        </StyledBlock>
    )
}

export default Block