import React from "react"
import styled from "styled-components"

import { MedMaxWidth } from "components/Layouts/MaxWidth"
import { H2, P } from "components/Typography"
import { Gradient, BackgroundImage } from "components/Layouts/Utils"

const StyledLanding = styled.section`
    position: relative;
    width: 100%;
    padding: 2rem;
    padding-top: 14rem;
    margin-bottom: 4rem;
    overflow: hidden;
    text-align: center;
    

    @media (max-width: 768px) {
        padding-top: 8rem;
        padding-bottom: 2rem;
        text-align: left;
        padding-left: 0rem;
        padding-right: 0rem;
    }
`

const Landing = ({
    title, text, img
}) => {
    return (
        <StyledLanding>
            <BackgroundImage
                src={img.url}
                loading="lazy"
            />
            <Gradient />

            <MedMaxWidth>
                <H2 color="white">{title}</H2>
                <P><span dangerouslySetInnerHTML={{ __html: text }} /></P>
            </MedMaxWidth>
        </StyledLanding>
    )
}

export default Landing